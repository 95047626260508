<template>
  <div>
    <v-row class="feed-list">
      <v-card min-width="100%" class="bg-main vh-100" flat tile>
        <v-container fluid class="pt-4">
          <h3 class="py-4 pl-1" v-if="timeClocks && timeClocks.length === 0 && !loading && !pending && $online">
            No timeclocks.
          </h3>
          <v-row dense v-if="$online">
            <v-btn class="btn-default pa-4 px-2 pr-3 ml-1" :to="{ name: 'timeclockdetail' }">
              <v-icon class="icon-grey mr-2">mdi-plus</v-icon>{{ $t("c_timeclocks.t_time") }}</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn class="btn-default mx-1" :to="{ name: 'reports' }" @click="loadTimeClocks"
              ><v-icon class="icon-grey mr-2">mdi-chart-box-outline</v-icon>{{ $t("c_timeclocks.t_report") }}
            </v-btn>
          </v-row>
          <v-row dense v-if="timeClocks && timeClocks.length">
            <v-col
              v-for="(tc, idx) in formatDateList(timeClocks, 'clockStart')"
              :key="`c-${idx}`"
              cols="12"
              class="py-0"
            >
              <div class="my-3 mt-4 pl-3 bg-header subtitle-2" v-if="tc.weekStart">
                {{ tc.weekStart }}
                <span v-if="tc.weekEnd">-</span>
                {{ tc.weekEnd }}
              </div>

              <div class="my-3 pl-1 subtitle-2">{{ tc.day }}</div>
              <v-card elevation="0" class="timeclock-wrap">
                <v-list two-line class="py-0 list-timeclocks">
                  <v-list-item-group active-class="active-timeclock">
                    <template>
                      <v-list-item
                        v-if="tc"
                        class="pl-0 py-0 my-0"
                        :key="`c-${idx}`"
                        :to="{ name: 'timeclockdetail', params: { timeClockId: tc.id } }"
                      >
                        <v-list-item-avatar
                          tile
                          size="68"
                          class="my-0 list-avatar"
                          :class="{ tall: tc.description }"
                          style="justify-content: center"
                        >
                          <h4 v-if="!tc.clockEnd && timeClockDuration && timeClockDuration.length > 3">
                            {{ timeClockDuration.substring(0, timeClockDuration.length - 3) }}
                          </h4>
                          <h4 v-else>{{ formatDuration(tc.clockStart, tc.clockEnd) }}</h4>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>
                            {{ tc.jobName }}
                          </v-list-item-title>

                          <v-list-item-subtitle>
                            {{ formatTime(tc.clockStart) }} -
                            <span v-if="getMonthDay(tc.clockStart) != getMonthDay(tc.clockEnd)">{{
                              formatDateShort(tc.clockEnd)
                            }}</span>
                            {{ tc.clockEnd ? formatTime(tc.clockEnd) : $t("t_clocked_in") }}
                          </v-list-item-subtitle>

                          <v-list-item-subtitle v-if="tc.description">
                            {{ tc.description }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action
                          v-if="$vuetify.breakpoint.xsOnly && !timeClockDuration"
                          class="ml-2 btn-preset"
                          @mousedown.stop=""
                          @touchstart.stop=""
                        >
                          <v-icon tile color="lime lighten-2" @click.prevent="$emit('startClockPreset', tc.id)">
                            mdi-play
                          </v-icon>
                        </v-list-item-action>
                        <template v-if="$vuetify.breakpoint.smAndUp">
                          <v-list-item-action class="mx-2" v-if="tc.costCodeCode">
                            <span class="mx-2 text-green">
                              <span>{{ $t("t_currency_symbol") }}</span>
                              <span v-if="$vuetify.breakpoint.smAndUp"> {{ tc.costCodeCode }}</span>
                            </span>
                          </v-list-item-action>
                          <v-list-item-action class="mx-2">
                            <span class="mx-0">{{ tc.timeCategoryName }}</span>
                          </v-list-item-action>
                          <v-list-item-action class="mx-2" @click.prevent="" @mousedown.stop="" @touchstart.stop="">
                            <v-tooltip top v-if="tc.status === 'a'">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small color="success" v-bind="attrs" v-on="on">
                                  <v-icon>mdi-eye-check</v-icon>
                                </v-btn>
                              </template>
                              <span>
                                Approved
                                {{ formatDateTimeContextual(tc.reviewedOn) }}
                              </span>
                            </v-tooltip>
                            <v-tooltip top v-else-if="tc.status === 'i'">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small color="warning" v-bind="attrs" v-on="on">
                                  <v-icon>mdi-eye-remove</v-icon>
                                </v-btn>
                              </template>
                              <span>
                                {{ $t("c_timeclocks.t_denied") }}
                                {{ formatDateTimeContextual(tc.reviewedOn) }}
                                <span v-html="'<br/>'"></span>
                                {{ $t("c_timeclocks.t_by") }} {{ tc.reviewedByFriendlyName }}
                              </span>
                            </v-tooltip>
                            <v-tooltip top v-else>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon else v-bind="attrs" v-on="on">
                                  <v-icon color="grey lighten-1">mdi-eye-off</v-icon>
                                </v-btn>
                              </template>
                              <span> {{ $t("c_timeclocks.t_pending_approval") }} </span>
                            </v-tooltip>
                          </v-list-item-action>
                          <v-list-item-action class="ml-0" v-if="tc.status === 'p' && $online">
                            <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  @click.prevent=""
                                  @mousedown.stop=""
                                  @touchstart.stop=""
                                >
                                  mdi-dots-vertical
                                </v-icon>
                              </template>
                              <v-list>
                                <v-list-item>
                                  <v-list-item-title @click="confirmRemoveTimeClock(tc.id)">{{
                                    $t("c_timeclocks.t_delete")
                                  }}</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-list-item-action>
                          <v-list-item-action class="ml-0" v-else></v-list-item-action>
                          <v-list-item-action
                            v-if="!timeClockDuration"
                            class="ml-3 btn-preset"
                            @click.prevent=""
                            @mousedown.stop=""
                            @touchstart.stop=""
                          >
                            <v-icon color="lime lighten-2" dense @click="$emit('startClockPreset', tc.id)">
                              mdi-play
                            </v-icon>
                          </v-list-item-action>
                        </template>
                      </v-list-item>

                      <!-- MOBILE TIMECLOCK META -->

                      <v-subheader v-if="$vuetify.breakpoint.xsOnly" style="border-top: 1px solid #333">
                        <v-list-item-action-text v-if="tc.costCodeCode">
                          <span class="mr-2 text-green" text-color="green">
                            <span>{{ $t("t_currency_symbol") }}</span>
                            <span class="pl-1 green--text"> {{ tc.costCodeCode }}</span>
                          </span>
                        </v-list-item-action-text>
                        <v-spacer v-if="tc.costCodeCode"></v-spacer>

                        <v-list-item-action-text>
                          <span class="mx-0">{{ tc.timeCategoryName }}</span>
                        </v-list-item-action-text>

                        <v-spacer></v-spacer>
                        <v-list-item-action>
                          <v-tooltip top v-if="tc.status === 'a'">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon small color="success" v-bind="attrs" v-on="on">
                                <v-icon>mdi-eye-check</v-icon>
                              </v-btn>
                            </template>
                            <span>
                              Approved on:
                              {{ formatDateTime(tc.approvedOn) }}
                            </span>
                          </v-tooltip>
                          <v-tooltip top v-else>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon else v-bind="attrs" v-on="on">
                                <v-icon color="grey lighten-1">mdi-eye-off</v-icon>
                              </v-btn>
                            </template>
                            <span> Pending approval </span>
                          </v-tooltip>
                        </v-list-item-action>
                      </v-subheader>
                    </template>
                    <div class="tasks">
                      <FormAssignments
                        v-if="idx == 0 && $online"
                        ref="assignments"
                        :parent="'timeclocks'"
                        :showFilters="false"
                        :showDateFormat="false"
                        :hasExpiresOn="true"
                        :takeNum="3"
                      >
                        <template v-slot:loadmore><span class="d-none"></span></template>
                      </FormAssignments>
                    </div>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-btn
          v-if="showLoadMore && !loading && timeClocks && timeClocks.length > 0"
          class="ma-3 mb-6"
          @click="loadMoreTimeClocks"
          >{{ $t("t_load_more") }}</v-btn
        >
      </v-card>
    </v-row>
    <AppConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import Helpers from "@/mixins/helpers";

import AppConfirmDialog from "@/components/AppConfirmDialog.vue";
import FormAssignments from "@/components/forms/FormAssignments.vue";

export default {
  name: "TimeClocks",
  props: ["fetchTimeClocks"],
  components: {
    AppConfirmDialog,
    FormAssignments,
  },
  mixins: [Helpers],
  computed: {
    ...mapGetters("timeClock", ["timeClocks", "activeTimeClock", "timeClockDuration"]),
    ...mapGetters("settings", ["settings"]),
    ...mapGetters("user", ["user"]),
    ...mapState("location", ["geoLocationCoords"]),
    ...mapState("loader", ["loading"]),
    clockedOut() {
      if (!this.activeTimeClock) {
        return true;
      }
      return false;
    },
  },
  data: () => ({
    showGeo: false,
    endOfResults: false,
    showLoadMore: false,
    pending: true,
  }),
  methods: {
    ...mapActions("timeClock", ["getTimeClocks", "appendTimeClocks", "deleteTimeClock"]),

    loadTimeClocks() {
      const params = {
        "paging.orderbyfield": "Id",
        "paging.ascending": "false",
        "paging.skipnum": 0,
        "paging.takenum": 25,
      };
      this.getTimeClocks(params)
        .then((response) => {
          if (!response) {
            console.log("Houston?");
          } else if (response.meta?.endOfResults) {
            this.showLoadMore = false;
          } else {
            this.showLoadMore = true;
          }
        })
        .finally(() => {
          this.pending = false;
        });
    },
    loadMoreTimeClocks() {
      const params = {
        "paging.orderbyfield": "Id",
        "paging.ascending": "false",
        "paging.skipnum": this.timeClocks?.length,
        "paging.takenum": 25,
      };
      this.appendTimeClocks(params).then((response) => {
        if (response.meta?.endOfResults) {
          this.showLoadMore = false;
        } else {
          this.showLoadMore = true;
        }
      });
    },
    async confirmRemoveTimeClock(timeClockId) {
      if (await this.$refs.confirm.open("Confirm", this.$t("c_confirm.t_confirm_delete"))) {
        this.removeTimeClock(timeClockId);
      }
    },
    removeTimeClock(timeClockId) {
      this.deleteTimeClock(timeClockId).then(() => {
        let dIndex = this.timeClocks.findIndex((t) => t.id === timeClockId);
        this.timeClocks.splice(dIndex, 1);
        this.responseMessage("c_snackbar.t_success_timeclock_deleted", 2000);
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.timeClocks || this.timeClocks.length < 1 || this.fetchTimeClocks) {
        this.loadTimeClocks();
      }
    });
  },
};
</script>
<style lang="scss">
.timeclocks {
  .timeclocks-wrapper {
    min-height: calc(100vh - 70px);
  }
  .clock-meta {
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
  }
  .shim-item {
    padding: 1px 0 4px 0;
  }
  .feed-list {
    .v-card {
      border: 1px solid rgb(212, 211, 211);
      .list-timeclocks {
        border-left: 5px solid rgb(233 177 30);
        background: transparent;
        .v-list-item {
          background: #fff;
          &.theme--dark {
            background: #1e1e1e;
          }
          margin-bottom: 8px;
        }
        .tasks {
          background: #fff;
          .form-item-wrap {
            .form-item {
              margin: 2px 0 0 0 !important;
            }
            margin: 0px 4px 4px 4px !important;
            &:last-child {
              margin-bottom: 0 !important;
            }
          }
        }
        .theme--dark {
          .tasks {
            background: #111;
          }

          .btn-preset {
            background: #00992b !important;
          }
        }
        .btn-preset {
          background: #00992b !important;
          padding: 3px;
          margin-right: -3px;
          border-radius: 3px;
        }
      }
    }
  }

  .sc-drawer {
    height: calc(100% - 110px);
    max-height: 100% !important;
  }
  .max-width-clockin {
    max-width: 400px !important;
  }
  .wide.v-avatar {
    height: 18px !important;
    min-width: 18px !important;
    width: 40px !important;
    border-radius: 3px !important;
  }
  .list-avatar {
    border-right: 1px solid #ccc;
    margin-left: 0px !important;
    &.tall {
      height: 84px !important;
    }
  }
  .v-subheader {
    border-bottom: #eee 1px solid;
    border-top: #eee 1px solid;
    &.theme--dark {
      border-bottom: #404040 1px solid;
      border-top: none;
    }
  }
  .link-subtle {
    color: inherit;
    text-decoration: none;
  }
  .v-list-item {
    &.theme--dark:hover {
      background-color: rgba(255, 255, 255, 0.2) !important;
    }
    &.theme--light:hover {
      background-color: rgba(0, 0, 0, 0.05) !important;
    }
  }
}
.theme--dark {
  .timeclocks {
    .list-avatar {
      border-right: 1px solid #474747;
    }
    .feed-list {
      .v-card {
        border: 1px solid rgb(51, 51, 51);
        &.timeclock-wrap {
          border: 1px solid rgb(51, 51, 51);
        }
      }
    }
  }
}
</style>
